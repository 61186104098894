<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData">

            <a-row :gutter="24" class="px-10 mt-10 pr-24 mb-5">
                <a-col :span="14" :md="18" :lg="18" :xl="18" class="">
                    <h5 class="pr-20"><a href="/classes" style="color: black;">Classes</a></h5>
                </a-col>
                <a-col :span="10" :md="6" :lg="6" :xl="6" class="text-right pt-5">
                    <a class="pr-10" style="font-size: 16px;" href="/classes">View All
                        <svg class="text-gray-800 dark:text-white" style="width: 14px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
                        </svg>
                    </a>
                </a-col>
            </a-row>

            <div class="scrollmenu mr-20">
                <div  style="width: 300px; height: 330px;" v-for="clas in classes" :key="clas.uuid">
                    <a-card id="zeroPaddingCard" class="p-0 m-0 pb-15" style="border-radius: 5px !important;">
                        <a class="text-dark p-0 m-0" style="text-decoration: none !important;" :href="'/classes/view/' + clas.uuid">
                            <a-row :gutter="24">
                                <a-col :span="24">
                                    <div class="icon">
                                        <img id="classImg" width="100%" height="200px" :src="clas.thumbnail != null ? clas.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row :gutter="24" class="px-20">
                                <a-col :span="20">
                                    <h6 class="mt-10 pt-4 pl-2">{{ clas.name.length > 20 ? `${clas.name.substring(0, 20)}...` : clas.name }}</h6>
                                </a-col>

                                <a-col :span="4"> 
                                    <font-awesome-icon icon="fa-solid fa-heart" class="mr-5 mt-10 pt-5" v-if="clas.favorite" style="color: red; width: 18px;"/>
                                    <font-awesome-icon icon="fa-regular fa-heart" class="mr-5 mt-10 pt-5" v-if="!clas.favorite" style="color: #616161; width: 18px;"/>
                                </a-col>

                                
                            </a-row>

                            <a-row :gutter="24" class="px-20">
                                <a-col :span="24" :md="14" :lg="14" :xl="14">
                                    <!-- <a-avatar :src="clas.creator != null && clas.creator.profilePicUrl ? clas.creator.profilePicUrl : ''" />&nbsp;&nbsp; -->
                                    
                                    <a-row :gutter="24" class="">
                                        <a-col :span="24" :md="24" :lg="24" :xl="24">
                                            {{ clas.creator != null && clas.creator.uuid != null ? clas.creator.firstName.length + clas.creator.lastName.length > 12 ? `${formatName(clas.creator.firstName)} .${clas.creator.lastName[0].toUpperCase()}` : `${formatName(clas.creator.firstName)} ${formatName(clas.creator.lastName)}` : 'Unknown Tutor'}}, Teacher
                                        </a-col>
                                        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-4">
                                            <svg v-for="(option, index) in clas.rating != null ? clas.rating : 0" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                            </svg>
                                            <svg v-for="(option, index) in clas.rating != null ? 5 - clas.rating : 5" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                            </svg>
                                        </a-col>
                                    </a-row>

                                </a-col>
                                <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pt-10">{{ clas.price != null && clas.price != 0 ? `${clas.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row>

                            <!-- <a-row :gutter="24" class="mt-10 px-20">
                                <a-col :span="24" :md="14" :lg="14" :xl="14">
                                    <a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
                                    <span><strong>{{ clas.slots + 1 - clas.membersCount }}</strong></span> <small>Slots remain </small>
                                </a-col>

                                <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pr-5">{{ clas.isPaid ? `${clas.joiningFee}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row> -->
                        </a>
                    </a-card>
                </div>
            </div>

            <div v-if="!loadingData && classes.length == 0">

                <a-row type="flex" :gutter="24" justify="space-around" align="middle" class="px-20" style="min-height: 30vh">

                    <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                        <img src="../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

                        <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No classes found</h6>
                        
                    </a-col>

                </a-row>
            </div>

		</div>

        <div v-if="!loadingData">

            <a-row :gutter="24" class="px-10 pr-24 mt-20 pt-20 mb-5">
                <a-col :span="14" :md="18" :lg="18" :xl="18" class="">
                    <h5 class="pr-20"><a href="/quizzes" style="color: black;">Quizzes</a></h5>
                </a-col>
                <a-col :span="10" :md="6" :lg="6" :xl="6" class="text-right pt-5">
                    <a class="pr-10" style="font-size: 16px;" href="/quizzes">View All
                        <svg class="text-gray-800 dark:text-white" style="width: 14px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
                        </svg>
                    </a>
                </a-col>
            </a-row>

            <div class="scrollmenu mr-20">
                <!-- <a-row :gutter="24" class="px-20 mt-0 pt-0"> -->

                    <!-- <a-col :span="24"  :md="6" :lg="6" :xl="6" class="mb-24"> -->

                    <div  style="width: 300px; height: 330px;" v-for="qui in quizes" :key="qui.uuid">
                        <a-card id="zeroPaddingCard" class="p-0 m-0 pb-15" style="border-radius: 5px !important;">
                            <router-link :to="`/quizzes/view/${qui.uuid}/start`" class="nav-link" @click="e => e.preventDefault()">
                                <a-row :gutter="24">
                                    <a-col :span="24">
                                        <div class="icon">
                                            <img id="classImg" width="100%" height="200px" :src="qui.thumbnail != null ? qui.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                        </div>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="24" class="px-20">
                                    <a-col :span="20">
                                        <h6 class="mt-10 pt-4 pl-2">{{ qui.title.length > 20 ? `${qui.title.substring(0, 20)}...` : qui.title }}</h6>
                                    </a-col>

                                    <a-col :span="4">
                                        <svg class="mt-10 pt-5 text-gray-800 dark:text-white" style="width: 18px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6C6.5 1 1 8 5.8 13l6.2 7 6.2-7C23 8 17.5 1 12 6Z"/>
                                        </svg>
                                    </a-col>

                                    
                                </a-row>

                                <a-row :gutter="24" class="px-20">
                                    <a-col :span="24" :md="14" :lg="14" :xl="14">
                                        <!-- <a-avatar :src="qui.creator != null && qui.creator.profilePicUrl ? qui.creator.profilePicUrl : ''" />&nbsp;&nbsp; -->
                                        Duration: {{ qui.duration }} minutes
                                        <!-- <a-row :gutter="24" class="">
                                            <a-col :span="24" :md="24" :lg="24" :xl="24">
                                                Duration: {{ qui.duration }} minutes
                                            </a-col>
                                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-4">
                                                Time: {{ $Moment(qui.updatedAt).format("DD MMM YYYY") }}
                                            </a-col>
                                        </a-row> -->
    
                                    </a-col>
                                    <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                        <h6 class="text-silabu pt-0">{{ qui.price != null && qui.price != 0 ? `${qui.price}/=` : 'FREE' }}</h6>
                                    </a-col>
                                </a-row>

                                <!-- <a-row :gutter="24" class="mt-10 px-20">
                                    <a-col :span="24" :md="14" :lg="14" :xl="14">
                                        <a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
                                        <span><strong>{{ qui.slots + 1 - qui.membersCount }}</strong></span> <small>Slots remain </small>
                                    </a-col>

                                    <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                        <h6 class="text-silabu pr-5">{{ qui.isPaid ? `${qui.joiningFee}/=` : 'FREE' }}</h6>
                                    </a-col>
                                </a-row> -->
                            </router-link>
                        </a-card>
                    </div>
                    <!-- </a-col>

                </a-row> -->
            </div>

            <div v-if="!loadingData && quizes.length == 0">

                <a-row type="flex" :gutter="24" justify="space-around" align="middle" class="px-20" style="min-height: 30vh">

                    <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                        <img src="../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

                        <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No quizzes found</h6>
                        
                    </a-col>

                </a-row>
            </div>

		</div>


        <div v-if="!loadingData" style="padding-bottom: 30px;">

            <a-row :gutter="24" class="px-10 pr-24 mt-20 pt-20 mb-5">
                <a-col :span="14" :md="18" :lg="18" :xl="18" class="">
                    <h5 class="pr-20"><a href="/notes" style="color: black;">Notes</a></h5>
                </a-col>
                <a-col :span="10" :md="6" :lg="6" :xl="6" class="text-right pt-5">
                    <a class="pr-10" style="font-size: 16px;" href="/notes">View All
                        <svg class="text-gray-800 dark:text-white" style="width: 14px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
                        </svg>
                    </a>
                </a-col>
            </a-row>

            <div v-if="!loadingData && notes.length == 0">

                <a-row type="flex" :gutter="24" justify="space-around" align="middle" class="px-20" style="min-height: 30vh">

                    <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                        <img src="../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

                        <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No notes found</h6>
                        
                    </a-col>

                </a-row>
            </div>

            <div class="scrollmenu mr-20 mb-20 pb-20">
                <!-- <a-row :gutter="24" class="px-20 mt-0 pt-0"> -->

                    <!-- <a-col :span="24"  :md="6" :lg="6" :xl="6" class="mb-24"> -->

                    <div  style="width: 300px; height: 330px;" v-for="not in notes" :key="not.uuid">
                        <a-card id="zeroPaddingCard" class="p-0 m-0 pb-15" @click="onViewFile(not)" style="border-radius: 5px !important;">
                            <!-- <a class="text-dark p-0 m-0" style="text-decoration: none !important;" :href="'/notes/view/' + not.uuid"> -->
                                <a-row :gutter="24">
                                    <a-col :span="24">
                                        <div class="icon">
                                            <img id="classImg" width="100%" height="200px" :src="not.thumbnail != null ? not.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                        </div>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="24" class="px-20">
                                    <a-col :span="20">
                                        <h6 class="mt-10 pt-4 pl-2">{{ not.title.length > 20 ? `${not.title.substring(0, 20)}...` : not.title }}</h6>
                                    </a-col>

                                    <a-col :span="4">
                                        <svg class="mt-10 pt-5 text-gray-800 dark:text-white" style="width: 18px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6C6.5 1 1 8 5.8 13l6.2 7 6.2-7C23 8 17.5 1 12 6Z"/>
                                        </svg>
                                    </a-col>

                                    
                                </a-row>

                                <a-row :gutter="24" class="px-20">
                                    <a-col :span="24" :md="14" :lg="14" :xl="14">
                                        <!-- <a-avatar :src="not.creator != null && not.creator.profilePicUrl ? not.creator.profilePicUrl : ''" />&nbsp;&nbsp; -->
                                        
                                        <a-row :gutter="24" class="">
                                            <a-col :span="24" :md="24" :lg="24" :xl="24">
                                                By {{ not.creator != null && not.creator.uuid != null ? not.creator.firstName.length + not.creator.lastName.length > 12 ? `${formatName(not.creator.firstName)} .${not.creator.lastName[0].toUpperCase()}` : `${formatName(not.creator.firstName)} ${formatName(not.creator.lastName)}` : 'Unknown Tutor'}}, Teacher
                                            </a-col>
                                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-4">
                                                Read: {{ not.viewCount }} Times
                                            </a-col>
                                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-5">
                                                <svg v-for="(option, index) in not.rating != null ? not.rating : 0" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                                </svg>
                                                <svg v-for="(option, index) in not.rating != null ? 5 - not.rating : 5" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                                </svg>
                                            </a-col>
                                        </a-row>
    
                                    </a-col>
                                    <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                        <h6 class="text-silabu pt-10">{{ not.price != null && not.price != 0 ? `${not.price}/=` : 'FREE' }}</h6>
                                    </a-col>
                                </a-row>
 
                                <!-- <a-row :gutter="24" class="mt-10 px-20">
                                    <a-col :span="24" :md="14" :lg="14" :xl="14">
                                        <a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
                                        <span><strong>{{ not.slots + 1 - not.membersCount }}</strong></span> <small>Slots remain </small>
                                    </a-col>

                                    <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                        <h6 class="text-silabu pr-5">{{ not.isPaid ? `${not.joiningFee}/=` : 'FREE' }}</h6>
                                    </a-col>
                                </a-row> -->
                            <!-- </a> -->
                        </a-card>
                    </div>
                    <!-- </a-col>

                </a-row> -->
            </div>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>

        <a-modal v-model="filter.showModal" :footer="null">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select all options that apply to you</h6>
				</a-col>
			</a-row>

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
				<a-col :span="24" class="">
                    <label class=""><strong>Type</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.type">
                        <a-radio :value="'classes'">Classes</a-radio>
                        <a-radio :value="'notes'">Notes</a-radio>
                        <a-radio :value="'quizzes'">Quizzes</a-radio>
                    </a-radio-group>
				</a-col>
			</a-row>

            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
				<a-col :span="24" class="">
                    <label class="mb-0 pb-0"><strong>Price</strong></label>
                </a-col>
                <a-col :span="24" class="mt-5 pt-0">
                    <a-slider class="mt-0 pt-0 mb-0 pb-0" v-model="filter.price" :tooltip-open="true" :step="slider.step" :max="slider.max" :trackStyle="{background: '#734EBF', color: '#734EBF'}"/>
                    <p style="font-size: 12px; position: absolute; right: 20px; top: -24px;">Less than: TZS <strong>{{ filter.price.toLocaleString() }}</strong></p>
				</a-col>
			</a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
				<a-col :span="24" class="mb-5 pb-0">
                    <label class="mb-0 pb-0"><strong>Tutor’s Rating</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-rate class="mt-0 pt-0" v-model="filter.tutorRating" />
				</a-col>
			</a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
				<a-col :span="24" class="">
                    <label class=""><strong>Start Time</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.startTime">
                        <a-radio :value="'today'">Starting Today</a-radio>
                        <a-radio :value="'week'">Starting Next week</a-radio>
                        <a-radio :value="'weeks'">Starting in 2 weeks</a-radio>
                    </a-radio-group>
				</a-col>
			</a-row>

			<a-row :gutters="24" class="mt-20 pt-20">
				<a-col :span="24" class="text-right">
					<a-button id="textBtn" type="text" class="text-primary txtBtn" @click="cancelFilter">
						Clear all filters
					</a-button>
					&nbsp;&nbsp;
					<a-button type="primary" class="text-white txtBtn" style="" @click="submitFilter">
						Appy filters
					</a-button>
				</a-col>
			</a-row>
			
		</a-modal>
	</div>
</template>

<script>
    import VueJwtDecode from 'vue-jwt-decode'
	import { notification } from 'ant-design-vue';

	export default ({
		

		data() {
			return {

				loadingData: false,

				classes: [],
                notes: [],
                quizes: [],
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

                slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    navigate: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getClasses();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getClasses();
                },
            },
            deep: true,
        },
		created() {
            this.$root.$refs.StudentHome = this;
            this.getUserDetails();
            this.getUserAcademicDetails();
			// this.getClasses()
            // this.getQuizzes();
            // this.getNotes();
			// this.getUserLocationDetails()
		},
		methods: {

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

            async onViewFile(item) {
				
                if((item.content.path != null && item.content.path.includes('.pdf')) || (item.thumbnail != null && item.thumbnail.includes('.pdf'))) {
                    await localStorage.setItem("pdfFilePath", item.content.path)
                    await localStorage.setItem("pdfFileName", item.thumbnail)

                    window.open(`/pdf-viewer`, '_blank');
                }
                else if((item.content.path != null && item.content.path.includes('.doc')) || (item.thumbnail != null && item.thumbnail.includes('.doc'))) {
                    await localStorage.setItem("docFilePath", item.content.path)
                    await localStorage.setItem("docFileName", item.thumbnail)

                    window.open(`/doc-viewer`, '_blank');
                }
                else if((item.content.path != null && item.content.path.includes('.mkv')) || (item.content.path != null && item.content.path.includes('.mp4'))){
                    await localStorage.setItem("videoFilePath", item.content.path)
                    await localStorage.setItem("videoFileName", item.thumbnail)

                    window.open(`/video-player`, '_blank');
                }
                else{
                    console.log(item.content)

                    this.notify('File format currently is not supported', 'error')
                }
				// this.$router.push(`/pdf-viewer`);
			},

			submitSearch(search) {
                this.pagination.search = search;
				this.pagination.currentPage = 1;

				this.getClasses();
                this.getNotes();
                this.getQuizzes();
			},

            async submitSearchFilter(filter) {
                this.filter.loading = false;
                this.pagination.search = filter.search;
                this.filter.price = filter.price;
                this.filter.isActive = filter.isActive;
                this.filter.navigate = true;
                filter.navigate = true;
				this.pagination.currentPage = 1;

                await localStorage.setItem('filter', JSON.stringify(filter))

				this.getClasses();
                this.getNotes();
                this.getQuizzes();
                
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getClasses();
                this.getNotes();
                this.getQuizzes();
			},

            launchFilter() {
                this.filter.showModal = true;
            },

            cancelFilter() {
                this.filter.isActive = false;
                this.filter.navigate = true;
                this.filter.showModal = false;
            },

            submitFilter() {
                if(this.filter.type != null) {
                    localStorage.setItem('filter', JSON.stringify(this.filter))
                    if(this.filter.type == 'classes') {
                        this.$router.push('/classes')
                    }
                    else if(this.filter.type == 'notes') {
                        this.$router.push('/notes')
                    }
                    else if(this.filter.type == 'quizzes') {
                        this.$router.push('/quizzes')
                    }
                }else{
                    this.notify(`Please select type before submittion`, 'error')
                }
            },


            async getUserDetails() {

				let token = await localStorage.getItem("user_token")

				let userInfo = VueJwtDecode.decode(token)

				if(userInfo != null && userInfo.data != null) {
                    await localStorage.setItem("user_details", JSON.stringify(userInfo.data))
				} 

			},

            async getUserAcademicDetails() {

				const userInfo = await localStorage.getItem('user_details')

                this.loadingData = true;

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					let url = `${this.$BACKEND_URL}/profiles?uuid=${userDetails.uuid}&populate=true`;

					this.$AXIOS.get(url).then((response) => {

                        if(response.data.data != null) {
                            this.getClasses(response.data.data.educationGrades.length > 0 ? response.data.data.educationGrades[0].educationLevel : null);
                            this.getNotes(response.data.data.educationGrades.length > 0 ? response.data.data.educationGrades[0].educationLevel : null);
                            this.getQuizzes(response.data.data.educationGrades.length > 0 ? response.data.data.educationGrades[0].educationLevel : null);
                        }else {
                            this.getClasses(null);
                            this.getNotes(null);
                            this.getQuizzes(null);
                        }
						
					
					}).catch(error => {
                        this.getClasses(null);
                        this.getNotes(null);
                        this.getQuizzes(null);

						this.notify(error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!", 'error');
					
					})

				}
				
			},

			async getClasses(educationLevel) {

				this.loadingData = true;

                const { currentPage, perPage, search } = this.pagination;

                let url = '';
                if(search != null && search.length > 0) {
                    url = `${this.$BACKEND_URL}/classes/?search=${search}&page=${currentPage}&size=${perPage}`;
                }else{
                    if(educationLevel != null) {
                        url = `${this.$BACKEND_URL}/classes/?setupCompleted=true&educationLevels=${educationLevel}&page=${currentPage}&size=${perPage}`;
                    }else{
                        url = `${this.$BACKEND_URL}/classes/?setupCompleted=true&page=${currentPage}&size=${perPage}`;
                    }
                }

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classes = response.data.data;
						this.pagination.totalPages = response.data.pagination.totalPages;
                        this.pagination.total = response.data.pagination.totalItems;
                        this.pagination.currentPage = response.data.pagination.currentPage;
						this.currentPage = response.data.pagination.currentPage;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getNotes(educationLevel) {

				this.loadingData = true;

                const { currentPage, perPage, search } = this.pagination;

                let url = '';
                if(search != null && search.length > 0) {
                    url = `${this.$BACKEND_URL}/materials/?search=${search}&page=${currentPage}&size=${perPage}`;
                }else{
                    if(educationLevel != null) {
                        url = `${this.$BACKEND_URL}/materials/?educationLevels=${educationLevel}&page=${currentPage}&size=${perPage}`;
                    }else{
                        url = `${this.$BACKEND_URL}/materials/?page=${currentPage}&size=${perPage}`;
                    }
                }

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.notes = response.data.data;
						this.pagination.totalPages = response.data.pagination.totalPages;
                        this.pagination.total = response.data.pagination.totalItems;
                        this.pagination.currentPage = response.data.pagination.currentPage;
						this.currentPage = response.data.pagination.currentPage;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getQuizzes(educationLevel) {

				this.loadingData = true;

                const { currentPage, perPage, search } = this.pagination;

                let url = '';
                if(search != null && search.length > 0) {
                    url = `${this.$BACKEND_URL}/quizzes/?search=${search}&page=${currentPage}&size=${perPage}`;
                }else{
                    if(educationLevel != null) {
                        url = `${this.$BACKEND_URL}/quizzes/?educationLevels=${educationLevel}&page=${currentPage}&size=${perPage}`;
                    }else{
                        url = `${this.$BACKEND_URL}/quizzes/?page=${currentPage}&size=${perPage}`;
                    }
                }

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.quizes = response.data.data;
						this.pagination.totalPages = response.data.pagination.totalPages;
                        this.pagination.total = response.data.pagination.totalItems;
                        this.pagination.currentPage = response.data.pagination.currentPage;
						this.currentPage = response.data.pagination.currentPage;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getUserLocationDetails() {

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/geo-location`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {

					await localStorage.setItem("locationDetails", JSON.stringify(response.data))
					
                }).catch(async(error) => {

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },

         

		},
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

a:hover {
    color: inherit !important;
}

#classImg {
	border-radius: 5px;
}

div.ant-select-selection, .ant-select-selection--single {
	border: none !important;
}


div.scrollmenu {
  /* background-color: #333; */
  overflow: auto;
  white-space: nowrap;
  /* overflow: hidden; */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}

div.scrollmenu > div {
  display: inline-block;
  color: white;
  /* text-align: center; */
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  /* background-color: #777; */
}
</style>