<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData">

            <a-row :gutter="24" class="px-20 pr-24 mt-10 pt-10 mb-5">
                <a-card style="background-color: #FFFFFF;">
                    <a-col :span="24" :md="12" :lg="12" :xl="12" class="pb-0">
                        <span>Revenue 💰</span> <h5 class="mb-0 pb-0"> TZS {{ totalEarning.toLocaleString() }}/=</h5>
                    </a-col>

                    <a-col :span="24" :md="12" :lg="12" :xl="12" class="pb-0 text-right">
                        <span>Balance</span> <h5 class="mb-0 pb-0">TZS {{ balance }}</h5>
                        <!-- <a-button type="primary" size="small">
                            Withdraw <font-awesome-icon icon="fa-solid fa-chevron-right" class="pr-0 mr-0 ml-10"/><font-awesome-icon icon="fa-solid fa-chevron-right" class="ml-0 pl-0 mr-0 pr-0"/>
                        </a-button> -->
                    </a-col>
                </a-card>
            </a-row>

            <a-row :gutter="24" class="px-10 pr-24 mt-10 pt-10 mb-5">
                <a-col :span="24" :md="8" :lg="8" :xl="8" class="mb-0 pb-0 text-center">
                    <router-link to="/institutions/students">
                        <a-card>
                            <h5> {{ institution.students }} Students </h5>

                            <a-avatar :size="50" class="mt-5">
                                <template #icon>
                                    <span>🧑🏽‍🎓</span>
                                </template>
                            </a-avatar>
                            <br/>
                            
                            <a-button id="textBtn" type="text" size="small" style="" class="mt-10">
                                See All 
                                <font-awesome-icon icon="fa-solid fa-angle-right" style="margin-top: -3px; margin-left: 3px; font-size: 13px;"/>
                            </a-button>
                        </a-card>
                    </router-link>
                </a-col>

                <a-col :span="24" :md="8" :lg="8" :xl="8" class="mb-0 pb-0 text-center">
                    <router-link to="/institutions/teachers">
                        <a-card>
                            <h5> {{ institution.tutors }} Teachers </h5>
                            <a-avatar :size="50" class="mt-5">
                                <template #icon>
                                    <span>🧑🏽‍🎓</span>
                                </template>
                            </a-avatar>
                            <br/>
                            
                            <a-button id="textBtn" type="text" size="small" style="" class="mt-10">
                                See All 
                                <font-awesome-icon icon="fa-solid fa-angle-right" style="margin-top: -3px; margin-left: 3px; font-size: 13px;"/>
                            </a-button>
                        </a-card>
                    </router-link>
                </a-col>

                <a-col :span="24" :md="8" :lg="8" :xl="8" class="mb-0 pb-0 text-center">
                    <router-link to="/institutions/admins">
                        <a-card>
                            <h5> {{ institution.admins }} Admins </h5>
                            <a-avatar :size="50" class="mt-5">
                                <template #icon>
                                    <span>👩🏽‍🏫</span>
                                </template>
                            </a-avatar>
                            <br/>
                            <a-button id="textBtn" type="text" size="small" style="" class="mt-10">
                                See All 
                                <font-awesome-icon icon="fa-solid fa-angle-right" style="margin-top: -3px; margin-left: 3px; font-size: 13px;"/>
                            </a-button>
                        </a-card>
                    </router-link>
                </a-col>
            </a-row>

            <a-row :gutter="24" class="px-10 pr-24 mt-20 pt-20 mb-5">
                <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-20 pb-10">
                    <a-card>
                        <a-row :gutter="24" class="mb-0 pb-0 pt-5 px-10">
                            <a-col :span="24" :md="14" :lg="14" :xl="14" class="mb-0 pb-0">
                                <router-link to="/institutions/my-classes/classes">
                                    <h6 style="font-size: 18px;">{{ loadingClassData ? '' : classes.length }} Group Classes</h6>
                                </router-link>
                            </a-col>
                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                                <h6 style="font-weight: normal;"><small>TZS</small> {{ classEarnings.toLocaleString() }}/=</h6>
                            </a-col>
                        </a-row>
                        
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    v-if="loadingClassData || (!loadingClassData && classes.length > 0)"
                                    class="ant-table-striped"
                                    :columns="columns" 
									:data-source="classes"
									:loading="loadingClassData" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;">

                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ index + 1 }}
                                        </template>

                                        <template slot="itemTitle" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail" shape="square" class="mr-5"/>
                                            {{ formatName(row.name.length > 35 ? `${row.name.substring(0, 35)}...` : row.name ) }}
                                        </template>

                                        <template slot="instructor" slot-scope="row"> 
                                            <!-- <a-avatar :src="row.thumbnail" class="mr-5"/> -->
                                            <!-- {{ `${row.tutor.firstName} ${row.tutor.lastName}` }} -->
                                            {{ row.coTutors.length == 0 ? row.coTutors[0] :  '-'}}
                                        </template>

                                        <template slot="students" slot-scope="row"> 
                                            <!-- {{ row.studentNo }} -->
                                            {{ row.members.length == 0 ? row.members.length : row.members - 1 }}
                                        </template>

                                        <template slot="price" slot-scope="row"> 
                                            {{ row.price != null && row.price > 0 ? `${row.price.toLocaleString()}/=` : 'Free' }}
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="viewItem(row, 'classes')"
                                                style="border-radius: 20px;">
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>

                                </a-table>
                            </a-col>
                        </a-row>


                        <div v-if="!loadingClassData && classes.length == 0">

                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 20vh">

                                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                    <img src="../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

                                    <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">
                                        You do not have any records on classes, create more classes
                                    </h6>

                                    <router-link to="/institutions/my-classes/create-new-class">
                                        <a-button id="textBtn" type="text">Create class</a-button>
                                    </router-link>
                                    
                                </a-col>

                            </a-row>
                        </div>

                        <a-row :gutter="24" class="mb-0 pb-10 mt-20">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0 text-right">
                                <router-link to="/institutions/classes">
                                    <a-button type="primary" size="small" style="">
                                        All Classes 
                                        <svg class="text-white ml-5" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                                        </svg>
                                    </a-button>
                                </router-link>
                            </a-col>
                        </a-row>
                        
                    </a-card>
                </a-col>

                <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-20 pb-10">
                    <a-card>

                        <a-row :gutter="24" class="mb-0 pb-0 pt-5 px-10">
                            <a-col :span="24" :md="14" :lg="14" :xl="14" class="mb-0 pb-0">
                                <router-link to="/institutions/quizzes">
                                    <h6 style="font-size: 18px;">{{ loadingQuizData ? '' : quizzes.length }} Quizzes</h6>
                                </router-link>
                            </a-col>
                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                                <h6 style="font-weight: normal;"><small>TZS</small> {{ quizEarnings.toLocaleString() }}/=</h6>
                            </a-col>
                        </a-row>
                        
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    v-if="loadingQuizData || (!loadingQuizData && quizzes.length > 0)"
                                    class="ant-table-striped"
                                    :columns="columns" 
									:data-source="quizzes"
									:loading="loadingQuizData" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;">

                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ index + 1 }}
                                        </template>

                                        <template slot="itemTitle" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail" shape="square" class="mr-5"/>
                                           {{ formatName(row.title.length > 35 ? `${row.title.substring(0, 35)}...` : row.title ) }}
                                        </template>

                                        <!-- <template slot="instructor" slot-scope="row, record, index">  -->
                                            <!-- <a-avatar :src="row.thumbnail" class="mr-5"/> -->
                                            <!-- {{ `${row.tutor.firstName} ${row.tutor.lastName}` }} -->
                                            <!-- {{ `Tutor ${index}` }}
                                        </template> -->

                                        <template slot="students" slot-scope="row"> 
                                            <!-- {{ row.studentNo }} -->
                                            {{ row.attemptsCount }}
                                        </template>

                                        <template slot="price" slot-scope="row"> 
                                            {{ row.price != null && row.price > 0 ? `${row.price.toLocaleString()}/=` : 'Free' }}
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="viewItem(row, 'quizzes')"
                                                style="border-radius: 20px;">
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>

                                </a-table>
                            </a-col>
                        </a-row>

                        <div v-if="!loadingQuizData && quizzes.length == 0">

                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 20vh">

                                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                    <img src="../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

                                    <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">
                                        You do not have any records on quizzes, create more quizzes
                                    </h6>

                                    <router-link to="/institutions/my-quizzes/create-new-quiz">
                                        <a-button id="textBtn" type="text">Create quiz</a-button>
                                    </router-link>
                                    
                                </a-col>

                            </a-row>
                        </div>
                        
                        <a-row :gutter="24" class="mb-0 pb-10 mt-20">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0 text-right">
                                <router-link to="/institutions/quizzes">
                                    <a-button type="primary" size="small" style="">
                                        All Quizzes 
                                        <svg class="text-white ml-5" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                                        </svg>
                                    </a-button>
                                </router-link>
                            </a-col>
                        </a-row>


                    </a-card>
                </a-col>

                <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-20 pb-10">
                    <a-card>

                        <a-row :gutter="24" class="mb-0 pb-0 pt-5 px-10">
                            <a-col :span="24" :md="14" :lg="14" :xl="14" class="mb-0 pb-0">
                                <router-link to="/institutions/notes">
                                    <h6 style="font-size: 18px;">{{ loadingNoteData ? '' : notes.length }} Notes</h6>
                                </router-link>
                            </a-col>
                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                                <h6 style="font-weight: normal;"><small>TZS</small> {{ noteEarnings.toLocaleString() }}/=</h6>
                            </a-col>
                        </a-row>
                        
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    v-if="loadingNoteData || (!loadingNoteData && notes.length > 0)"
                                    class="ant-table-striped"
                                    :columns="columns" 
									:data-source="notes"
									:loading="loadingNoteData" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;">


                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ index + 1 }}
                                        </template>

                                        <template slot="itemTitle" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail" shape="square" class="mr-5"/>
                                            {{ formatName(row.title.length > 35 ? `${row.title.substring(0, 35)}...` : row.title ) }}
                                        </template>

                                        <!-- <template slot="instructor" slot-scope="row, record, index">  -->
                                            <!-- <a-avatar :src="row.thumbnail" class="mr-5"/> -->
                                            <!-- {{ `${row.tutor.firstName} ${row.tutor.lastName}` }} -->
                                            <!-- {{ `Tutor ${index}` }} -->
                                        <!-- </template> -->

                                        <template slot="students" slot-scope="row"> 
                                            <!-- {{ row.studentNo }} -->
                                            {{ row.completed.length }}
                                        </template>

                                        <template slot="price" slot-scope="row"> 
                                            {{ row.price != null && row.price > 0 ? `${row.price.toLocaleString()}/=` : 'Free' }}
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="viewItem(row, 'notes')"
                                                style="border-radius: 20px;">
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>

                                </a-table>
                            </a-col>
                        </a-row>


                        <div v-if="!loadingNoteData && notes.length == 0">

                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 20vh">

                                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                    <img src="../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

                                    <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">
                                        You do not have any records on notes, create more notes
                                    </h6>

                                    <router-link to="/institutions/my-notes/publish-new-notes">
                                        <a-button id="textBtn" type="text">Create notes</a-button>
                                    </router-link>
                                    
                                </a-col>

                            </a-row>
                        </div>

                        <a-row :gutter="24" class="mb-0 pb-10 mt-20">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0 text-right">
                                <router-link to="/institutions/notes">
                                    <a-button type="primary" size="small" style="">
                                        All Notes 
                                        <svg class="text-primary ml-5" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                                        </svg>
                                    </a-button>
                                </router-link>
                            </a-col>
                        </a-row>

                    </a-card>
                </a-col>

            </a-row>

		</div>


		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>



        <a-drawer
            title="Details"
            placement="right"
            :closable="true"
            :visible="itemDetails.showDrawer"
            @close="onCloseDrawer"
            width="560">
            
            <a-row :gutter="24">
                <a-col :span="24">

                    <a-row :gutter="24">
                        <a-col :span="24">
                            <h5>{{ itemDetails.title }}</h5>
                        </a-col>

                        <a-col :span="24">
                            <img id="classImg" width="100%" height="250px" :src="itemDetails.thumbnail" alt="">
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10" v-if="itemDetails.type == 'classes'">
                        <a-col :span="12">
                            <label><strong>Instructor</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <!-- {{ itemDetails.instructor }} -->
                        </a-col>
                    </a-row>

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Start Date</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.startDate }}
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>{{ itemDetails.type == 'classes' ? 'Members' : itemDetails.type == 'notes' ? 'Completed' : 'Attempts' }}</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.members }}
                        </a-col>
                    </a-row>

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Completed the course</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.courseCompletition }}
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10" v-if="itemDetails.type == 'classes'">
                        <a-col :span="12">
                            <label><strong>Slots</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.slots }}
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Price</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <span style="font-size: 10px; font-weight: 600;" v-if="itemDetails.price != 0 && itemDetails.price != null">TZS </span> {{ itemDetails.price != 0 && itemDetails.price != null ? itemDetails.price.toLocaleString() : 'Free' }}
                        </a-col>
                    </a-row>

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Total Revenue</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <span style="font-size: 10px; font-weight: 600;">TZS </span> {{ itemDetails.totalRevenue }}
                        </a-col>
                    </a-row> -->

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Tutor’s Rating</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.totalRating }}
                        </a-col>
                    </a-row> -->
                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Education System</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.educationSystem.name }}
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Education Level</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.educationLevel.name }}
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Grades</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-badge 
                                v-for="option in itemDetails.grades"
                                :key="option.uuid"
                                style="font-size: 12px !important;" 
                                :count="`${option.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Subjects</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-badge 
                                v-for="option in itemDetails.subjects"
                                :key="option.uuid"
                                style="font-size: 12px !important;" 
                                :count="`${option.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 pt-20 px-10">
                        <a-col :span="24" class="text-center mt-20">
                            <a-button
                                type="primary"
                                class="ml-15"
                                size="small"
                                @click="toManageItemDetails"
                                style="border-radius: 20px;">
                                <a-icon type="setting" theme="outlined" />
                                Manage {{ itemDetails.type == 'classes' ? 'Class' : itemDetails.type == 'notes' ? 'Notes' : 'Quiz'}}
                            </a-button>
                        </a-col>
                    </a-row>

                </a-col>
            </a-row>
        </a-drawer>
        
	</div>
</template>

<script>
	import { notification } from 'ant-design-vue';

    const columns = [
        {
            title: 'S/N',
            scopedSlots: { customRender: 's_no' },
            width: 80
        },
        {
            title: 'Title',
            scopedSlots: { customRender: 'itemTitle' },
            width: 200
        },
        {
            title: 'Instructor',
            scopedSlots: { customRender: 'instructor' },
            width: 200
        },
        {
            title: '# of Students',
            scopedSlots: { customRender: 'students' },
            key: 'sudents',
            width: 100
        },
        {
            title: 'Price',
            scopedSlots: { customRender: 'price' },
            width: 100
        },
        {
            title: 'View',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];

	export default ({
		
		data() {
			return {

				loadingData: false,
                loadingClassData: true,
                loadingNoteData: true,
                loadingQuizData: true,

				classes: [],
                notes: [],
                quizzes: [],
                categories: [],
                classColumns: [],
                columns,
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

                totalEarning: 0,
                balance: 0,

                classEarnings: 0,

                quizEarnings: 0,

                noteEarnings: 0,

                itemDetails: {
                    uuid: null,
                    title: null,
                    thumbnail: null,
                    instructor: null,
                    startDate: null,
                    members: null,
                    courseCompletition: null,
                    educationLevel: {},
                    educationSystem: {},
                    subjects: [],
                    category: {},
                    slots: null,
                    price: null,
                    totalRevenue: null,
                    totalRating: null,
                    label: null,
                    type: null,
                    showDrawer: false,
                },


                institution: {
                    uuid: null,
                    name: null,
                    institutionType: null,
                    emailAddress: null,
                    phoneNumber: null,
                    location: null,
                    country: null,
                    educationSystems: null,
                    educationLevels: null,
                    subjects: null,
                    admins: null,
                    students: null,
                    tutors: null,
                    creator: null,
                    createdAt: null,
                    updatedAt: null,
                    verifyMembers: null,
                },
                

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    navigate: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },
      		}
		},
		created() {
            this.getInstitutionProfile();
		},
		methods: {

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },


            toCreateClassPage() {
                this.$router.push('/institutions/my-classes/create-new-class')
            },

            toCreateNotePage() {
                this.$router.push('/institutions/my-notes/publish-new-notes')
            },

            toCreateQuizPage() {
                this.$router.push('/institutions/my-quizzes/create-new-quiz')
            },

            toManageItemDetails() {
                if(this.itemDetails.type == 'classes') {
                    this.$router.push(`/institutions/my-classes/view/${this.itemDetails.uuid}`)
                }

                if(this.itemDetails.type == 'quizzes') {
                    this.$router.push(`/institutions/my-quizzes/view/${this.itemDetails.uuid}`)
                }

                if(this.itemDetails.type == 'notes') {
                    this.$router.push(`/institutions/my-notes/view/${this.itemDetails.uuid}`)
                }
            },


            async getInstitutionProfile() {

				this.loadingData = true;

                let userInfo = await localStorage.getItem("user_details")

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {}
                // uuid=${userDetails.uuid}&
                let url = `${this.$BACKEND_URL}/institutions?creator=${userDetails.uuid}`;
                
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        if(response.data.data != null && response.data.data.length > 0) {
                            this.institution.uuid = response.data.data[0].uuid;
                            this.institution.name = response.data.data[0].name;
                            this.institution.institutionType = response.data.data[0].institutionType;
                            this.institution.emailAddress = response.data.data[0].emailAddress;
                            this.institution.phoneNumber = response.data.data[0].phoneNumber;
                            this.institution.location = response.data.data[0].location;
                            this.institution.country = response.data.data[0].country;
                            this.institution.educationSystems = response.data.data[0].educationSystems;
                            this.institution.educationLevels = response.data.data[0].educationLevels;
                            this.institution.subjects = response.data.data[0].subjects;
                            this.institution.tutors = response.data.data[0].totalTutors;
                            this.institution.admins = response.data.data[0].totalAdmins;
                            this.institution.students = response.data.data[0].totalStudents;
                            this.institution.creator = response.data.data[0].creator;
                            this.institution.createdAt = response.data.data[0].createdAt;
                            this.institution.updatedAt = response.data.data[0].updatedAt;
                            this.institution.verifyMembers = response.data.data[0].verifyMembers;

                            let { admins, tutors, students, ...details } = response.data.data[0];

                            localStorage.setItem('institution_details', JSON.stringify(details));

                            this.getInstitutionReports(response.data.data[0].uuid);
                            this.getInstitutionClasses(response.data.data[0].uuid);
                            this.getInstitutionNotes(response.data.data[0].uuid);
                            this.getInstitutionQuizzes(response.data.data[0].uuid);
                        }
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getInstitutionReports(institutionUuid) {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/payments/reports?uuid=${institutionUuid}&populate=true&isForInstitution=true`;
                
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        if(response.data.data != null && response.data.data.length > 0) {
                            response.data.data.forEach(element => {

                                if(element.price != null) {
                                    this.totalEarning += element.price;
                                }

                                if(element.isClazz && element.price != null) {
                                    this.classEarnings += element.price;
                                }

                                if(element.isMaterial && element.price != null) {
                                    this.noteEarnings += element.price;
                                }

                                if(element.isQuiz && element.price != null) {
                                    this.quizEarnings += element.price;
                                }
                            });

                    
                        }
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getInstitutionClasses(institutionUuid) {

				this.loadingClassData = true;

                const currentPage = 1; 
                const perPage = 5;

                let url = `${this.$BACKEND_URL}/classes?institution=${institutionUuid}&page=${currentPage}&size=${perPage}&populate=true`;
                 
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classes = response.data.data;

                        this.loadingClassData = false;
                    }
					
                }).catch(async(error) => {
					
					this.loadingClassData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getInstitutionNotes(institutionUuid) {

				this.loadingNoteData = true;

                const currentPage = 1; 
                const perPage = 5;

                let url = `${this.$BACKEND_URL}/materials?institution=${institutionUuid}&page=${currentPage}&size=${perPage}&populate=true`;
                 
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.notes = response.data.data;

                        this.loadingNoteData = false;
                    }

					
					
                }).catch(async(error) => {
					
					this.loadingNoteData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },



            async getInstitutionQuizzes(institutionUuid) {

				this.loadingQuizData = true;

                const currentPage = 1; 
                const perPage = 5;

                let url = `${this.$BACKEND_URL}/quizzes?institution=${institutionUuid}&page=${currentPage}&size=${perPage}&populate=true`;
                 
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.quizzes = response.data.data;

                        this.loadingQuizData = false;
                    }

                }).catch(async(error) => {
					
					this.loadingQuizData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },



            viewItem(item, type) {
                this.itemDetails.uuid = item.uuid;
                this.itemDetails.title = type == 'classes' ? item.name : item.title;
                this.itemDetails.thumbnail = item.thumbnail;
                // this.itemDetails.instructor = item.coTutors;
                this.itemDetails.startDate = item.dateTime != null ? this.$Moment(item.dateTime).format('DD MMM YYYY') : item.dateTime;
                this.itemDetails.members = type == 'classes' ? item.members.length : type == 'notes' ? item.completed.length : item.attempts.length;
                this.itemDetails.educationSystem = item.educationSystems != null && item.educationSystems.length > 0 ? item.educationSystems[0] : {};
                this.itemDetails.educationLevel = item.educationLevels != null && item.educationLevels.length > 0 ? item.educationLevels[0] : {};
                this.itemDetails.grades = item.educationGrades;
                this.itemDetails.subjects = item.subjects;
                this.itemDetails.category = item.category;
                // this.itemDetails.courseCompletition = '4';
                this.itemDetails.slots = item.numOfSlots;
                this.itemDetails.price = item.price;
                this.itemDetails.type = type;
                // this.itemDetails.totalRevenue = item.price;
                // this.itemDetails.totalRating = item.rating != null ? item.rating : 5;
                this.itemDetails.label = null
                this.itemDetails.showDrawer = true;

                // this.category.category = clazz.ca
            },



            onCloseDrawer() {
                this.itemDetails.showDrawer = false;
                this.itemDetails.uuid = null;
                this.itemDetails.title = null;
                this.itemDetails.thumbnail = null;
                this.itemDetails.instructor = null;
                this.itemDetails.startDate = null;
                this.itemDetails.members = null;
                this.itemDetails.courseCompletition = null;
                this.itemDetails.price = null;
                this.itemDetails.totalRevenue = null;
                this.itemDetails.totalRating = null;
                this.itemDetails.label = null;
            },


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },

         

		},
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

a:hover {
    color: inherit !important;
}

#classImg {
	/* border-radius: 10px; */
}

div.ant-select-selection, .ant-select-selection--single {
	border: none !important;
}


div.scrollmenu {
  /* background-color: #333; */
  overflow: auto;
  white-space: nowrap;
  /* overflow: hidden; */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}

div.scrollmenu > div {
  display: inline-block;
  color: white;
  /* text-align: center; */
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}
</style>